@import '../../common/styles/variables.scss';

.main {
  overflow-y: hidden;
  // overflow-wrap: anywhere;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: absolute;
  top: 0;
  padding-top: 56px;
  width: 100%;

  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  .body {
    margin:auto;

    .logo {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
